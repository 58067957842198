import { Injectable } from "@angular/core";
import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PagingInterceptorService {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.urlWithParams?.includes("_page") &&
      req.urlWithParams?.includes("_limit")
    ) {
      const newUrl = { url: req.urlWithParams.split("_like").join("") };
      console.log(newUrl);

      req = Object.assign(req, newUrl);
    }

    return next.handle(req).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          if (event.body.totalItems) {
            let newHeaders = new HttpHeaders({
              "X-Total-Count": String(event.body.totalItems),
            });

            let newEvent = event.clone({ headers: newHeaders });

            return newEvent;
          }

          return event;
        }

        return event;
      })
    );
  }
}
