export default {
     productionRequestUrl: "https://api-coastwatch.dd.softwebpages.com/v1",
  devRequestUrl: "https://api-coastwatch.dd.softwebpages.com/v1",
  

  tokenTypeBearer: true, // type of the Auth token is bearer
  API_KEY: "fsf4f1fasfsdf374re1f",
  ID: "id", //id or _id
  ID2: "_id", //id or _id
  licenceID: "string", // the id to take the activated modules,
  image_field_name: "image", // το ονομα που εχει το πεδιο με τις εικονες στο project
  maxImageSize: 2000000,
  logo: "",
  projectName: "Coastwatch",
};